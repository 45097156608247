@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400&display=swap');

body {
  font-family: 'Cairo', ui-sans-serif, sans-serif;
  @apply antialiased
}

/* body {
  @apply font-sans antialiased;
} */

main {
  @apply p-4;
}

section {
  @apply my-10;
}

footer {
  @apply bg-gray-800 text-white py-10 text-center;
}

footer .social-media a {
  @apply inline-block mx-2;
}

footer .social-media img {
  @apply w-6 h-6;
}